import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";

// Importing all pages
import Index from "./pages/Index";
import Walletpi from "./pages/Walletpi";
import { initMetaPixel, trackPageView } from "./utils/metaPixel";

export interface AppProps {
  lang: string;
}
function App() {
  useEffect(() => {
    const pixelId = "952811763271292"; // Replace with your Meta Pixel ID
    initMetaPixel(pixelId);
    trackPageView(); // Track page view when the app is loaded
  }, []);

  return (
    <Routes>
      {/* en */}
      <Route path="/" element={<Index lang="en" />} />
      <Route path="/en" element={<Index lang="en" />} />
      <Route path="/en/walletpi" element={<Walletpi lang="en" />} />

      {/* vi */}
      <Route path="/vi" element={<Index lang="vi" />} />
      <Route path="/vi/walletpi" element={<Walletpi lang="vi" />} />

      {/* cn */}
      <Route path="/cn" element={<Index lang="cn" />} />
      <Route path="/cn/walletpi" element={<Walletpi lang="cn" />} />
    </Routes>
  );
}

export default App;
