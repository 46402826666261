import React, { useEffect } from "react";
import "../styles.css";
import { AppProps } from "../App";

function Index({ lang }: AppProps) {
  useEffect(() => {
    window.addEventListener("message", function (event) {
      if (event.data && event.data.type === "FROM_IFRAME") {
        window.location.href = `${lang}/walletpi`;
      }
    });
    return () => {
      // document.body.removeChild(script);
    };
  }, []);

  return (
    <iframe
      src="/vong-quay-pi-network.html"
      width="100%"
      height="100%"
      title="hello"
    />
  );
}

export default Index;
